import React from "react"
import { injectIntl } from "react-intl"
import { 
  CurrentMonitoringsDonut, 
  CurrentDevicesInUseDonut, 
  RecordedAlertsColumnChart
} from "../Charts"
import { 
  Col,
  Row
} from "react-bootstrap"
import {
  Card
} from "@material-ui/core"
import { getHomeCharts } from "api/home"
import { PCurrentMonitoringsData } from "../Charts/CurrentMonitoringsDonut"
import { PCurrentDevicesInUse } from "../Charts/CurrentDevicesInUseDonut"
import { PRecordedAlertsColumnChartData } from "../Charts/RecordedAlertsColumnChart"
import { times as _times } from "lodash"

function HomePage(props: any) {
  const [currentMonitorings, setCurrentMonitorings] = React.useState<PCurrentMonitoringsData>()
  const [currentDevicesInUse, setCurrentDevicesInUse] = React.useState<PCurrentDevicesInUse>()
  const [recordedAlerts, setRecordedAlerts] = React.useState<PRecordedAlertsColumnChartData>()

  React.useEffect(() => {
    getHomeCharts()
      .then((res) => {
        setCurrentMonitorings({
          active: res.monitoringsQtd,
          total: res.pacientsQtd
        })
        setCurrentDevicesInUse({
          inUse: res.monitoringsQtd,
          total: res.devicesQtd
        })
        setRecordedAlerts(sortRecordedAlerts(res.alerts))
      })
  }, [])

  function sortRecordedAlerts(alerts: any): PRecordedAlertsColumnChartData {
    const cHour = (new Date()).getHours()
    const order: Array<string> = []
    const values: Array<number> = []
    
    _times(12, (idx: number) => {
      const vIndex = cHour - idx
      if (vIndex >= 0) {
        order.unshift(`${vIndex}`)
        values.unshift(alerts[vIndex])
      } else {
        const backwardIndex = 24 - Math.abs(vIndex)
        order.unshift(`${backwardIndex}`)
        values.unshift(alerts[backwardIndex])
      }
    })

    return {
      categories: order.map((v) => `${v.padStart(2, "0")}:00` ),
      values: values
    }
  }

  return (
    <>
      <Row>
        <Col lg={3}>
          <Card className="p-5 dark-card">
            <CurrentMonitoringsDonut
              data={currentMonitorings}
              height={300}
              />
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="p-5 dark-card">
            <CurrentDevicesInUseDonut
              data={currentDevicesInUse}
              height={300}
              />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={6}>
          <Card className="p-5 dark-card">
            <RecordedAlertsColumnChart
              data={recordedAlerts}
              height={300}
              />
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default injectIntl((HomePage))

export const BradenQScales = {
  EMPTY     : "EMPTY",
  HIGH      : "HIGH",
  MEDIUM    : "MEDIUM",
  LOW       : "LOW",
  NONE      : "NONE",
}
export function bradenQScaleRangeStyles(_scalePoints: number | string) {
  let scalePoints = _scalePoints !== "" ? _scalePoints : 0

  switch(true) {
    case (scalePoints === 0): 
      return {
        variant: "info",
        color: "text-info",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.EMPTY",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.EMPTY.SHORT",
        category: BradenQScales.EMPTY,
        index: 0
      }
    case (scalePoints <= 16): 
      return {
        variant: "danger",
        color: "text-danger",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.HIGH",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.HIGH.SHORT",
        category: BradenQScales.HIGH,
        index: 4
      }
    case (scalePoints <= 21): 
      return {
        variant: "warning",
        color: "text-warning",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.MEDIUM",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.MEDIUM.SHORT",
        category: BradenQScales.MEDIUM,
        index: 3
      }
    case (scalePoints <= 25): 
      return {
        variant: "primary",
        color: "text-primary",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.LOW",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.LOW.SHORT",
        category: BradenQScales.LOW,
        index: 2
      }
    default: 
      return {
        variant: "success",
        color: "text-success",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.NONE",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE_Q.RANGE.NONE.SHORT",
        category: BradenQScales.NONE,
        index: 1
      }
  }
}